import StoryblokClient from 'storyblok-js-client';
import { StoryblokService } from '../storyblok';
import { Link, LinkMap, StoryblokLinkNode } from './types';

const createMapFromId = (list: StoryblokLinkNode[]): LinkMap => list
  .reduce((a, v) => ({ ...a, [v.id]: v }), {});

const isTypeUrl = (link: Link): boolean => (link.linktype === 'url');
const mockLink = {
  url: '',
  cached_url: '',
  linktype: '',
  story: {
    url: '',
  },
};

export const NavigationService = {
  rootAlias: process.env.GATSBY_HOME_STORY_SLUG,

  // primary and secondary nav
  currentPageCssClass: 'current-page',

  getParsedMultiLink(link: Link): string {
    if (NavigationService.isExternalUrl(link)) {
      return NavigationService.getParsedLink(link);
    }

    const url = NavigationService
      .getParsedLink(link)
      .replace(NavigationService.rootAlias, '');
    const anchor = link.anchor ? `#${link.anchor}` : '';

    return `/${url}${anchor}`
      .replace('//', '/')
      .replace('/#', '#');
  },

  isExternalUrl: (link: Link): boolean => link.url !== '',

  isWhitelistedDomain: (url: string): boolean => {
    try {
      const whitelist: string[] = JSON.parse(process.env.GATSBY_WHITE_LISTED_DOMAINS) || [];
      const { hostname } = new URL(url);

      return whitelist.some((domain: string) => hostname.endsWith(domain));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Invalid URL or whitelist', error);
      return false;
    }
  },

  isHttpLink: (link: Link): boolean => (NavigationService.getParsedLink(link).startsWith('http')),

  getParsedLink: (link: Link = mockLink): string => encodeURI(link.url || link.story?.url || ''),

  isCurrentPage: (link: Link): boolean => (
    `${global.window?.location.pathname}/` === `/${NavigationService.getParsedLink(link)}`
  ),

  getParsedNavigationLink: (link: Link = mockLink): string => {
    if (isTypeUrl(link) && !NavigationService.isHttpLink(link)) {
      return `https://${NavigationService.getParsedLink(link)}`;
    }

    if (isTypeUrl(link)) {
      return NavigationService.getParsedLink(link);
    }

    return `/${NavigationService.getParsedLink(link)}`.replace('//', '/');
  },

  getCurrentPagePath(): string {
    const url = new URL(window.location.href);
    const { pathname } = url;

    // Account for both editor page and regular pages.
    return ['editor', '/editor', '/editor/'].indexOf(pathname) >= 0 ? url.searchParams.get('path') : pathname;
  },

  // breadcrumb
  storyblokClient: new StoryblokClient({
    accessToken: StoryblokService.getConfig().options.accessToken as string,
  }),
  async getAllLinks(): Promise<LinkMap> {
    const allLinks = await this.storyblokClient.getAll('cdn/links', { version: StoryblokService.getConfig().options.headers.Version });
    return createMapFromId(allLinks);
  },
  async getLink(path: string): Promise<StoryblokLinkNode | undefined> {
    const links = await this.storyblokClient.getAll('cdn/links', { version: StoryblokService.getConfig().options.headers.Version, starts_with: path });
    const sanitazedPath = path.startsWith('/') ? path : `/${path}`;

    return links.find((link: StoryblokLinkNode) => (
      link.real_path === sanitazedPath
    ));
  },
  async getBreadcrumbsFromId(currentId: number, allLinks?: LinkMap): Promise<StoryblokLinkNode[]> {
    const links = allLinks || await this.getAllLinks();

    if (!links[currentId]) {
      return [];
    }

    let { parent_id: parentId } = links[currentId];
    const breadcrumbs: StoryblokLinkNode[] = [links[currentId]];

    while (parentId > 0) {
      breadcrumbs.push(links[parentId]);
      parentId = links[parentId]?.parent_id;
    }

    return breadcrumbs.reverse();
  },
  async getBreadcrumbsFromPath(path: string): Promise<StoryblokLinkNode[]> {
    const currentLink = await NavigationService.getLink(path);

    if (!currentLink?.real_path) {
      return [];
    }

    const links = currentLink.real_path.slice(1).split('/');

    const breadcrumbs = await Promise.all(links.map(async (link, index) => {
      const previous = [...links.slice(0, index), link];
      return NavigationService.getLink(previous.join('/'));
    }));

    return breadcrumbs;
  },
};
