import { StoryData } from 'storyblok-js-client';
import { datadogRum } from '@datadog/browser-rum';
import { StoryDataFromGraphQLQuery } from '../../templates/types';
import { getCacheBuster } from '../../utils/get-cache-buster';
import { parseCludoEngineID } from '../../utils/parse-cludo-engine-id';
import { retry } from '../../utils/retry';
import { LanguageService } from '../language';
import { StoryblokService } from '../storyblok';
import { StringService } from '../string';
import { TeaserService } from '../teaser';
import { GlobalConfigProps } from './types';

export interface WindowWithOnetrust extends Window {
  OneTrust: {
    Close: () => void
    IsAlertBoxClosed: () => boolean
  };
}

type HTMLElementContent = string | { toString: () => string };
interface StoryDataWithLocaleInjection extends StoryData {
  lang: string;
  allSpaceLocales: string[];
}

const additionalWhiteListedDomains: string[] = ['assets.roche.com', 'assets.cwp.roche.com'];

/* Route is always the same: pages/topic/* */
const getTagPageUrl = (): string => 'topic';

const pageTypes: Record<string, string> = {
  page: 'Page',
  story: 'Story',
};

export const DomService = {
  createElement(tagName: string, content: HTMLElementContent = '', props: Record<string, string> = {}): HTMLElement {
    const element = document.createElement(tagName);
    element.innerHTML = typeof content === 'string' ? content : content.toString();
    Object.keys(props).forEach((prop) => {
      element.setAttribute(prop, props[prop]);
    });
    return element;
  },

  activateDatadogMonitoring(): void {
    if (process.env.GATSBY_DATADOG_APPLICATION_ID === 'default'
      || process.env.GATSBY_DATADOG_CLIENT_TOKEN === 'default') {
      return;
    }

    const service = process.env.WEBSITE_STAGE === 'prod'
      ? 'corporate-website-platform-(cwp)---global'
      : 'roche-global';

    datadogRum.init({
      applicationId: process.env.GATSBY_DATADOG_APPLICATION_ID,
      clientToken: process.env.GATSBY_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service,
      env: process.env.WEBSITE_STAGE || 'test',
      version: process.env.GATSBY_CURRENT_RELEASE_VERSION || 'local',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  },

  activateConsentScript(): void {
    const oneTrustScriptPlaceholder = document.getElementById('oneTrustScriptPlaceholder');
    if (!oneTrustScriptPlaceholder) {
      return;
    }

    const activatedOneTrustScript = document.createElement('script');
    const oneTrustScriptSettings = {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      type: 'text/javascript',
      charSet: 'UTF-8',
      async: 'true',
      defer: 'true',
      'data-domain-script': `${process.env.GATSBY_ROCHE_ONETRUST_KEY}`,
      'data-document-language': 'true',
    };

    Object.entries(oneTrustScriptSettings)
      .forEach(([name, value]) => activatedOneTrustScript.setAttribute(name, value));
    document.head.replaceChild(activatedOneTrustScript, oneTrustScriptPlaceholder);
    this.setupConsentReEvaluation();
  },

  setupConsentReEvaluation(): void {
    const observer = new MutationObserver(this.handleConsentRelevantDomChanges);
    observer.observe(document.documentElement, { childList: true, subtree: true });
  },

  handleConsentRelevantDomChanges(records: MutationRecord[]): void {
    const shouldTriggerConsentEvaluation = records
      .some((record) => Array.from(record.addedNodes).some(({ type }: HTMLScriptElement) => type === 'text/plain'));

    if (!shouldTriggerConsentEvaluation) {
      return;
    }

    retry(DomService.triggerConsentEvaluation, Infinity)
      .catch(() => undefined);
  },

  async triggerConsentEvaluation(): Promise<void> {
    const { OneTrust } = window as unknown as WindowWithOnetrust;

    if (OneTrust?.IsAlertBoxClosed()) {
      OneTrust?.Close();
      return Promise.resolve();
    }

    return Promise.reject();
  },

  getParsedLocale(locale: string): string {
    return locale === 'default' ? LanguageService.getDefaultLocale() : locale;
  },

  getGlobalConfig(
    story: StoryDataFromGraphQLQuery,
    defaultLanguageHome?: StoryDataFromGraphQLQuery,
  ): GlobalConfigProps {
    const {
      id: storyblokStoryId,
      uuid: pageId,
      lang: locale,
      content,
      allSpaceLocales,
    } = story as StoryDataWithLocaleInjection;
    // TODO: Confirm tag page url should remain localised
    const tagPage = LanguageService.getLocaleAwareLink(getTagPageUrl(), locale);

    const whiteListedDomains: string[] = [...additionalWhiteListedDomains];

    try {
      whiteListedDomains.push(JSON.parse(process.env.GATSBY_WHITE_LISTED_DOMAINS));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Parsing GATSBY_WHITE_LISTED_DOMAINS failed: ', error);
    }

    return {
      allSpaceLocales,
      alternates: story.alternates
        .map((alternate) => {
          const {
            fullSlug: graphQlApiSlug,
            full_slug: contentDeliveryApiSlug,
            published,
            teaser_title: analiticsTeaserTitle,
            name,
          } = alternate;
          const slug = defaultLanguageHome?.id === alternate.id
            ? LanguageService.getHomePageUrl(defaultLanguageHome)
            : (graphQlApiSlug || contentDeliveryApiSlug);

          return published || StoryblokService.isInEditor()
            ? ({
              locale: LanguageService.getLocaleFromSlug(slug),
              url: slug,
              analiticsTeaserTitle,
              name,
            })
            : undefined;
        })
        .filter(Boolean),
      tagPageUrl: tagPage,
      baseDomain: process.env.GATSBY_BASE_DOMAIN,
      brightcoveAccountId: process.env.GATSBY_BRIGHTCOVE_ACCOUNT_ID,
      brightcovePlayerId: process.env.GATSBY_BRIGHTCOVE_PLAYER_ID,
      cludoCustomerID: process.env.GATSBY_CLUDO_CUSTOMER_ID,
      cludoEngineId:
        process.env.GATSBY_CLUDO_ENGINE_ID
        || parseCludoEngineID(process.env.GATSBY_CLUDO_ENGINE_ID_LIST, locale),
      cognitoUserpoolClientId: process.env.GATSBY_COGNITO_USERPOOL_CLIENT_ID,
      cognitoUserpoolId: process.env.GATSBY_COGNITO_USERPOOL_ID,
      formsApiUrl: process.env.GATSBY_FORMS_API_URL,
      headerIconUrl: StringService.trimUserInput(process.env.GATSBY_ROCHE_HEADER_ICON_URL),
      locale,
      nonDefaultThemeSubcategories: TeaserService.nonDefaultThemeSubcategories,
      pageId: `storyblok:${process.env.GATSBY_STORYBLOK_SPACE_API_KEY_NAME}:${pageId}`,
      recaptchaKey: process.env.GATSBY_GOOGLE_RECAPTCHA_KEY,
      translationUrl: `/translations/${locale}.json${getCacheBuster()}`,
      tagsTranslationUrl: `/tags-translations/${locale}.json${getCacheBuster()}`,
      popularSearchesTranslationUrl: `/popular-searches-translations/${locale}.json${getCacheBuster()}`,
      twitterHandle: process.env.GATSBY_TWITTER_HANDLE,
      websiteName: process.env.GATSBY_WEBSITE_NAME,
      whiteListedDomains: JSON.stringify(whiteListedDomains),
      isShareHidden: content.hide_share || false,
      gapiClientId: process.env.GATSBY_GAPI_CLIENT_ID,
      gapiKey: process.env.GATSBY_GAPI_KEY,
      // TODO: remove when the cluod API is moved to the Gatsby Repository
      cludoFuzziness: process.env.GATSBY_CLUDO_FUZZINESS,
      storyblokStoryId,
      componentLibrary: process.env.GATSBY_ROCHE_COMPONENTS_LIBRARY_URL,
      websiteDomain: StringService.removeProtocolAndSubdomains(process.env.GATSBY_WEBSITE_DOMAIN),
    };
  },

  getPageCategory(componentName: string): string {
    return pageTypes[componentName];
  },

  getPageType(
    isHomePage: boolean,
    pageType: string,
  ): string {
    if (isHomePage) {
      return 'Home';
    }

    if (pageType === 'story') {
      return 'Story';
    }

    if (pageType === 'page') {
      return 'Page';
    }

    return 'Other';
  },
};
